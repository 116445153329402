import React from 'react'
import { trackCustomEvent } from 'gatsby-plugin-google-analytics'

import Main from '../layout/Main'
// import Image from '../components/image'
import SEO from '../components/seo'
import ButtonLink from '../components/ButtonLink'
import {
  IndexView,
  Title,
  DateContainer,
  Steps,
  OrText
} from '../pageStyles/indexStyle'
import EmailSubscribe from '../components/EmailSubscribe'

const IndexPage = () => {
  const handleClickLink = (type) => {
    trackCustomEvent({
      // string - required - The object that was interacted with (e.g.video)
      category: type,
      // string - required - Type of interaction (e.g. 'play')
      action: 'Click',
      // string - optional - Useful for categorizing events (e.g. 'Spring Campaign')
      label: 'Step Action',
      // number - optional - Numeric value associated with the event. (e.g. A product ID)
      // value: 43
    })
    return true
  }

  return (
    <Main>
      <SEO title="Logan High School Class of 2000 Reunion" />
      <IndexView>

        <Title>Announcing the Logan High School Class of 2000 20-year Reunion!</Title>
        <p>
          Can you believe it's been 20 years? Planning for our reunion is well under-way, but all we have so far is the date. Please save the date!
        </p>

        <DateContainer>
          <h3 style={{textDecoration: 'line-through'}}>Logan Country Club</h3>
          <h2 style={{textDecoration: 'line-through'}}>July 25, 2020</h2>
          <h3 style={{color: 'red'}}>** The reunion has been postponed until 2021. Subscribe with your email below for updates.</h3>
        </DateContainer>

        <Steps>
          
          <p>
            Time is TBD. We'd like to keep you updated as plans develop, and we need help spreading the word. Here's what we need you to do:
          </p>

          <h2>1. Sign up for announcements</h2>

          <p>
            Registrations aren't open yet, but we'd like to get your email so we can notify you with updates. Also, we'll hold on to these emails for the next reunion too!
          </p>

          <EmailSubscribe />

          <h2>2. Join our Facebook Group</h2>

          <p>
            If Facebook is your thing, you definitely want to join this group.
          </p>

          <ButtonLink onClick={() => handleClickLink('FB_GROUP')} href="https://www.facebook.com/groups/2553371984875835" target="_blank" rel="noopener noreferrer">
            Join the Facebook Group
          </ButtonLink>

          <h2>3. Save the date to your calendar</h2>

          <p>
            Add this to your calendar now so you don't forget! The exact time might change, but if you signed up for announcements, we'll let you know.
          </p>

          <p>
            <ButtonLink onClick={() => handleClickLink('ADD_TO_CALENDAR')} href="https://www.google.com/calendar/render?action=TEMPLATE&text=LHS+20-year+Reunion&details=Check+the+website+for+updates+leading+up+to+the+event%3A+https%3A%2F%2Floganhigh2000.com%2F&location=Logan+Country+Club+710+N+1500+E%2C+Logan%2C+UT+84321&dates=20200726T010000Z%2F20200726T030000Z"  target="_blank" rel="noopener noreferrer">
              Add to Google Calendar
            </ButtonLink>
            <OrText>&nbsp; - or - &nbsp;</OrText>
            <ButtonLink onClick={() => handleClickLink('ADD_TO_CALENDAR')} href="webcal://images/LHS_20Year_Reunion.ics">Add to iCal</ButtonLink>
          </p>

          <h2>4. Look for an invitation to register</h2>

          <p>
            Leading up to the event you'll all be able to register online - so look for our announcement (see step 1 above).
          </p>

          <h2>5. Share this webpage with everyone you know!</h2>

          <p>
            Get the word out any way you can. Share it on social media, text, email, etc.
          </p>

          <p>
            <ButtonLink onClick={() => handleClickLink('FB_SHARE')} target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Floganhigh2000.com%2F&amp;src=sdkpreparse">Share on Facebook</ButtonLink>
          </p>
        
        </Steps>
      </IndexView>
    </Main>
  )
}

export default IndexPage
