import React from 'react'
import MailchimpSubscribe from 'react-mailchimp-subscribe'

import SubscribeForm from './SubscribeForm'
import {
  FormWrapper
} from './style'

const EmailSubscribe = () => {

  const url = 'https://loganhigh2000.us19.list-manage.com/subscribe/post?u=7ef86bcd48aee8b1118383f4b&id=f7ca51cb1f'

  return (
    <div>
      <FormWrapper>
        <MailchimpSubscribe
          url={url}
          render={({ subscribe, status, message }) => (
            <>
              {status !== 'sending' && status !== 'error' && status !== 'success' ? (
                <SubscribeForm onSubmit={subscribe} />
              ) : null}
              {/* <MyForm onSubmitted={formData => subscribe(formData)} /> */}
              {status === 'sending' && <div style={{ color: 'blue' }}>sending...</div>}
              {status === 'error' && <div style={{ color: 'red' }} dangerouslySetInnerHTML={{__html: message}}/>}
              {status === 'success' && <div style={{ color: 'green' }}>Thanks for subscribing!</div>}
            </>
          )}
        />
        
      </FormWrapper>
      
    </div>
  )

}

export default EmailSubscribe
