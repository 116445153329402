import React, {useState} from 'react'
import PropTypes from 'prop-types'

const SubscribeForm = ({ onSubmit }) => {
  const [email, setEmail] = useState('')

  const handleSubmit = () => {
    onSubmit({
      EMAIL: email
    })
  }

  return (
    <form onSubmit={handleSubmit} className="validate" noValidate>
      <div>
        <label htmlFor="mce-EMAIL">Enter your Email:</label>
        <input type="email" value={email} name="EMAIL" className="email" id="mce-EMAIL" placeholder="Your Email" required onChange={e => setEmail(e.target.value)} />
        <input type="submit" value="Subscribe to Updates" name="subscribe" id="mc-embedded-subscribe" className="button" />
      </div>
    </form>
  )

}

SubscribeForm.propTypes = {
  onSubmit: PropTypes.func.isRequired
}

export default SubscribeForm
