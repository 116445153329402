import styled from 'styled-components'

import {getColor, media} from '../styleUtils'

export const IndexView = styled.div`
  display: block;
  text-align: center;
  width: 600px;
  max-width: 100%;
  margin: 0 auto;
`

export const Title = styled.h1`
  max-width: 100%;
`

export const DateContainer = styled.div`
  width: 250px;
  margin: 30px auto 0;
  border-top: 1px solid ${getColor('gray')};
  padding: 15px 15px 0;

  h2{
    margin-top: 0;
  }

  h3{
    margin-bottom: 0;
  }
`

export const Steps = styled.div`
  text-align: left;
  margin: 20px 0;
  border-top: 1px solid ${getColor('crimson')};
  padding-top: 30px;
`

export const OrText = styled.span`
  text-align: center;
  display: block;
  margin-bottom: 5px;
  ${media.small`
    text-align: left;
    display: inline;
    margin-bottom: 0;
  `}
`
