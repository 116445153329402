import styled from 'styled-components'
import PropTypes from 'prop-types'

import {getColor, media} from '../styleUtils'

const ButtonLink = styled.a`
  background: ${getColor('crimson')};
  color: ${getColor('white')};
  padding: 6px 10px;
  border-radius: 6px;
  border: 1px solid ${getColor('crimson')};
  text-decoration: none;
  display: block;
  width: 100%;
  margin-bottom: 5px;
  text-align: center;
  ${media.small`
    display: ${props => props.block ? 'block' : 'inline-block'};
    width: auto;
    margin-bottom: 0;
  `}

  &:hover, &:focus{
    text-decoration: none;
    background-color: ${getColor('crimson', 'light')};
    color: ${getColor('white')};
  }
`

ButtonLink.propTypes = {
  block: PropTypes.bool,
}

export default ButtonLink
