import styled from 'styled-components'

import {getColor, media} from '../../styleUtils'

export const FormWrapper = styled.div`
  display: block;

  label{
    margin-right: 10px;
    display: block;
    margin-bottom: 5px;
    ${media.small`
      display: inline-block;
      margin-bottom: 0;
    `}
  }

  .email{
    margin: 0 0 5px;
    border-radius: 6px;
    padding: 6px 10px;
    border: 1px solid ${getColor('gray')};
    display: block;
    width: 100%;
    ${media.small`
      margin: 0 10px 0 0;
      display: inline-block;
      width: auto;
    `}
  }

  .button{
    background: ${getColor('crimson')};
    color: ${getColor('white')};
    padding: 6px 10px;
    border-radius: 6px;
    border: 1px solid ${getColor('crimson')};
    display: block;
    width: 100%;
    margin-bottom: 5px;
    ${media.small`
      display: inline-block;
      width: auto;
      margin-bottom: 0;
    `}

    &:hover, &:focus{
      background-color: ${getColor('crimson', 'light')};
      color: ${getColor('white')};
    }
  }
`
